<template>
  <div class="status_container">
    <!-- 支付成功 -->
    <div class="content" v-if="isPay === 1">
      <div class="title">
        <van-icon name="passed" color="rgb(13, 190, 13)" size="50px" />
        <span>支付成功</span>
      </div>
      <div class="info">
        <van-cell title="诊疗卡号" :value="patCardNo" :border="false" />
        <van-cell title="住院人" :value="name" :border="false" />
        <van-cell title="科室" :value="deptName" :border="false" />
        <van-cell title="入院时间" :value="inDate" :border="false" />
        <van-cell title="出院时间" :value="outDate" :border="false" />
        <van-cell title="缴费金额" :value="cost + '元'" :border="false" />
      </div>
      <div class="btn">
        <van-button type="info" @click="back">返&nbsp;回&nbsp;首&nbsp;页</van-button>
        <van-button type="primary" @click="record">查&nbsp;看&nbsp;记&nbsp;录</van-button>
      </div>
    </div>
    <!-- 已退款 -->
    <div class="content" v-if="isPay === 2">
      <div class="title">
        <van-icon name="passed" color="rgb(13, 190, 13)" size="50px" />
        <span>已退款</span>
      </div>
      <div class="info">
        <van-cell title="诊疗卡号" :value="patCardNo" :border="false" />
        <van-cell title="流水号" :value="hospitalOrderNum" :border="false" />
        <van-cell title="温馨提示" :value="remark" :border="false" />
      </div>
      <div class="btn">
        <van-button type="info" @click="back">返&nbsp;回&nbsp;首&nbsp;页</van-button>
        <van-button type="primary" @click="record">查&nbsp;看&nbsp;记&nbsp;录</van-button>
      </div>
    </div>
    <!-- 支付中 -->
    <div class="content" v-if="isPay === 0 || isPay === 7">
      <div class="title">
        <van-icon name="passed" color="rgb(13, 190, 13)" size="50px" />
        <span>支付异常</span>
      </div>
      <div class="info">
        <van-cell title="诊疗卡号" :value="patCardNo" :border="false" />
        <van-cell title="流水号" :value="hospitalOrderNum" :border="false" />
        <van-cell title="温馨提示" :value="remark" :border="false" />
      </div>
      <div class="btn" v-if="isPay === 7">
        <van-button type="info" @click="refresh" class="refresh">刷&nbsp;&nbsp;新</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Status",
  data () {
    return {
      name: "",
      deptName: "",
      inDate: "",
      outDate: "",
      cost: "",
      isPay: "",
      patCardNo: "",
      outTradeNo: "",
      hospitalOrderNum: "",
      remark: ""
    };
  },
  created () {
    this.name = this.$route.query.name;
    this.deptName = this.$route.query.deptName;
    this.inDate = this.$route.query.inDate;
    this.outDate = this.$route.query.outDate;
    this.cost = this.$route.query.cost;
    this.outTradeNo = this.$route.query.outTradeNo;
    this.patCardNo = this.$route.query.patCardNo;
    this.getPayStatus();
  },
  methods: {
    //查询支付状态
    async getPayStatus () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        outTradeNo: this.outTradeNo
      });
      const { data: res } = await formPost(
        "/wxapp/pay/orderRecord/getOrderPayStatus",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        console.log(res);
        this.isPay = res.data.isPay;
        this.patCardNo = res.data.medicalCardNo;
        this.hospitalOrderNum = res.data.hospitalOrderNum;
        this.id = res.data.id;
        if (res.data.isPay === 1) {
          let remark = res.data.remark.replace(/，/gi, ",");
          this.remark = remark.split(",");
          this.getInvoice();
        } else if (res.data.isPay === 0) {
          this.remark = "支付异常，请到人工窗口办理";
        } else if (res.data.isPay === 2) {
          this.remark = res.data.remark;
        } else if (res.data.isPay === 7) {
          this.remark =
            "系统繁忙或网络异常，业务未能处理完成，请点击“刷新”按钮重试，或到人工窗口办理";
        }
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //刷新重新调起支付
    async recommit () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        id: this.id,
        outTradeNo: this.outTradeNo
      });
      const { data: res } = await formPost("/wxapp/pay/wxpay/recommit", data);
      this.$toast.clear();
      if (res.code === 0) {
        this.isPay = 1;
        this.orderInfo.remark = res.data.remark;
      } else {
        this.$toast(res.msg);
      }
    },
    refresh () {
      this.recommit();
    },
    back () {
      this.$router.push({
        path: "/deposit"
      });
    },
    record () {
      this.$router.push({
        path: "/order/deposit",
        query: {
          patCardNo: this.patCardNo
        }
      });
    }
  }
};
</script>

<style scope>
.status_container {
  padding-bottom: 60px;
}

.status_container .content {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border: 1px solid #f5f5f5;
  box-shadow: darkgrey 10px 10px 30px 5px;
}

.status_container .content .title {
  position: relative;
  height: 180px;
  font-size: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 120px;
}

.status_container .content .info .van-cell__title,
.status_container .content .info .van-cell__value {
  font-size: 40px;
  line-height: 70px;
}

.status_container .content .info .van-cell__value {
  flex: 2 !important;
}

.status_container .content .btn {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
  padding: 0 40px;
}

.status_container .content .btn .van-button {
  font-size: 36px;
}

.status_container .content .btn .refresh {
  width: 100%;
}
</style>